html {
  margin: 56px 0 0 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-item {
  border: 0px solid red;
  height: calc(100vh - 56px);
  max-height: 70%;
  width: 100%;
}

@media (max-width: 576px) {
  .carousel-item {
    height: calc(70vh - 56px);
    max-height: 70%;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .carousel-item {
    height: calc(70vh - 56px);
    max-height: 70%;
    width: 100%;
  }
}

.nav-link {
  text-align: center;
}

.hstack {
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.imagesBox {
  height: 100%;
  width: 100%;
  border: 0px solid green;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}